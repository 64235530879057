<template>
  <v-dialog v-model="dialog" @click:outside="cancelSave" width="1240">
    <v-card>
      <v-container>
        <v-form ref="form">
          <h3 class="mb-1 mt-2">Toistuvuus</h3>

          <h3 class="success--text" v-if="active && inUse">Aktiivinen</h3>

          <v-row dense>
            <v-col cols="7" sm="5" md="5" lg="3">
              <v-checkbox
                v-model="inUse"
                label="Käytä määräaikaisia tuotteita"
                style="margin-top: 0px"
              ></v-checkbox>
            </v-col>
          </v-row>

          <small v-if="inUse && !active">
            Voit muokata alkamispäivää niin kauan, kunnes ensimmäinen lasku on lähtenyt ja
            toistuvuus on aktivoitu.
          </small>

          <v-row dense>
            <v-col v-if="inUse" cols="6" sm="4" md="4" lg="3">
              <v-text-field
                v-model.number="repeatsLeft"
                v-only-numbers
                v-prevent-paste
                outlined
                dense
                :rules="greaterThanZero"
                label="Toistoja jäljellä"
                suffix="kpl"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col v-if="inUse" cols="6" sm="4" md="4" lg="3">
              <v-menu
                ref="startMenu"
                v-model="startMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                max-width="290px"
                min-width="290px"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    :value="startDateFormatted"
                    outlined
                    dense
                    label="Alkaen kk"
                    v-bind="attrs"
                    :rules="required"
                    v-on="on"
                    append-icon="mdi-calendar"
                    ref="startDateFormatted"
                    v-prevent-manual-input
                    hide-details
                    :disabled="active"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :allowed-dates="allowedDates"
                  type="month"
                  first-day-of-week="1"
                  @input="startMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <small v-if="inUse && !active">
            Voit valita määräaikaisten tuotteiden toistuvuuden alkaen
            {{ formatDateMonthName(currentInvoice.nextDueDate) }}
          </small>

          <!-- Overview -->
          <v-row v-if="inUse && repeatsLeft > 0 && startDateExact" dense class="mt-3 mb-3">
            <v-col cols="12" sm="10" md="6" lg="5">
              <div class="invoice-overview">
                <p>
                  Laskun ensimmäinen eräpäivä on
                  <span class="contract-text font-weight-bold">{{
                    formatDate(startDateExact)
                  }}</span>
                </p>
                <p>
                  Laskun toistoja jäljellä
                  <span class="contract-text font-weight-bold">{{ repeatsLeft }} kpl</span>
                </p>
                <p>
                  Toistuvuusväli on
                  <span class="contract-text font-weight-bold"
                    >{{ currentInvoice.invoiceInterval }} kk</span
                  >
                </p>
                <p>
                  Laskun viimeinen eräpäivä on
                  <span class="contract-text font-weight-bold">{{ formatDate(lastDueDate) }}</span>
                </p>
              </div>
            </v-col>
          </v-row>

          <div v-show="inUse">
            <!-- Products  -->
            <h3 class="mb-1 mt-2">Määräaikaiset tuotteet</h3>

            <div v-for="(product, index) in currentInvoice.fixedPeriodProducts" :key="index">
              <v-row dense class="mb-1">
                <v-col cols="12" md="3" lg="2" class="pr-1">
                  <label>Valitse tuote</label>
                  <v-autocomplete
                    v-model="product.productId"
                    :items="products"
                    item-text="desc"
                    item-value="_id"
                    dense
                    outlined
                    return-object
                    small-chips
                    @focus="getInvoiceProducts"
                    @change="setProductToState($event, { ...product }, index)"
                    hide-details
                    :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="6" sm="6" md="2" lg="2" class="pr-1">
                  <v-text-field
                    v-model.number="product.desc"
                    outlined
                    dense
                    :rules="required"
                    label="Kuvaus"
                    hide-details
                  />
                </v-col>
                <v-col cols="6" sm="6" md="2" lg="2" class="pr-1">
                  <v-text-field
                    v-model.number="product.amount"
                    outlined
                    dense
                    step="0.01"
                    type="number"
                    :rules="required.concat(positiveNumber)"
                    label="Hinta"
                    suffix="€"
                    hide-details
                  />
                </v-col>

                <v-col cols="6" sm="6" md="2" lg="1" class="pr-1">
                  <v-text-field
                    v-model.number="product.count"
                    outlined
                    dense
                    v-only-numbers
                    v-prevent-paste
                    :rules="required.concat(positiveNumber)"
                    label="Määrä"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="2" lg="2" class="pr-1">
                  <v-select
                    v-model="product.itemtype"
                    outlined
                    dense
                    :items="productItemTypes"
                    label="Tuotetyyppi"
                    return-object
                    hide-details
                  ></v-select>
                </v-col>

                <v-col cols="6" sm="6" md="3" lg="2">
                  <div class="details-container">
                    <v-select
                      v-model="product.taxpr"
                      outlined
                      dense
                      :items="vatTypes"
                      item-value="val"
                      item-text="text"
                      label="Alv-kanta"
                      hide-details
                    ></v-select>
                    <p
                      class="ml-4 mt-1 delete-btn error--text"
                      text
                      @click="deleteProduct({ index, productType: 'fixedPeriodProducts' })"
                    >
                      X
                    </p>
                  </div>
                </v-col>
              </v-row>

              <div v-if="product.accountingId" style="margin-top: 5px">
                <p style="font-size: 13px">Tiliöintikoodi: {{ product.accountingId }}</p>
                <v-divider class="mb-1 mt-2"></v-divider>
              </div>
              <v-divider v-else class="mt-2 mb-1"></v-divider>
            </div>

            <v-row class="mt-2" dense>
              <v-col>
                <v-btn
                  small
                  color="primary"
                  class="mr-4 mb-2"
                  @click="addProduct('fixedPeriodProducts')"
                  >Lisää tuoterivi</v-btn
                >
              </v-col>
            </v-row>
          </div>

          <!-- VATS -->
          <vat-component
            v-if="currentInvoice.fixedPeriodProducts.length > 0 && inUse"
            class="mt-3 mb-3"
            :products="currentInvoice.fixedPeriodProducts"
          ></vat-component>

          <v-card-actions class="pa-0">
            <v-btn outlined color="error" @click="cancelSave">Poistu</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="info" @click="saveAllValues">Tallenna</v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import globalValues from "../../configs/globalValues";
import VatComponent from "@/components/Invoice/VatComponent";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    VatComponent,
  },

  data(vm) {
    return {
      startMenu: false,
      vatTypes: globalValues.vatTypes,
      productItemTypes: globalValues.productItemTypes,
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => vm.checkPositiveNumber(v) || "Anna positiivinen luku"],
      greaterThanZero: [(v) => vm.checkGreaterThanZero(v) || "Anna positiivinen luku"],
    };
  },

  props: {
    value: { type: Boolean, default: false },
    lastDueDate: {
      type: String,
      default: null,
    },
    startDateExact: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState("invoice", ["currentInvoice"]),
    ...mapState("invoice", ["recurrentInvoice"]),
    ...mapState("product", ["products"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    active() {
      return this.currentInvoice.fixedPeriodRecurrency.active;
    },

    inUse: {
      get() {
        return this.currentInvoice.fixedPeriodRecurrency.inUse;
      },
      set(val) {
        this.setFormField({ val, field: "fixedPeriodRecurrency.inUse" });
      },
    },

    repeatsLeft: {
      get() {
        return this.currentInvoice.fixedPeriodRecurrency.repeatsLeft;
      },
      set(val) {
        this.setFormField({ val, field: "fixedPeriodRecurrency.repeatsLeft" });
      },
    },

    startDate: {
      get() {
        return this.currentInvoice.fixedPeriodRecurrency.startDate;
      },
      set(val) {
        this.setFormField({ val, field: "fixedPeriodRecurrency.startDate" });
      },
    },

    startDateFormatted() {
      return this.formatDateMonthName(this.currentInvoice.fixedPeriodRecurrency.startDate);
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.getInvoiceProducts();
      }
    },
  },

  methods: {
    ...mapActions("product", ["getInvoiceProducts"]),
    ...mapMutations("invoice", ["setFormField", "addProduct", "setProduct", "deleteProduct"]),
    ...mapMutations("invoice", ["cancelFixedPeriodFields"]),

    saveAllValues() {
      if (this.$refs.form.validate()) {
        if (this.inUse) {
          if (this.currentInvoice.fixedPeriodProducts.length == 0) {
            this.showPopup("Lisää vähintään yksi tuote!", "error");
            return;
          }
        }
        this.showPopup("Määräaikaiset tuotteet päivitetty. Muista tallentaa lasku.", "primary");

        this.dialog = false;
      } else {
        this.showPopup("Tarkista kaikki pakolliset kentät", "error");
      }
    },

    cancelSave() {
      this.cancelFixedPeriodFields();
      this.dialog = false;
    },

    setProductToState(event, product, index) {
      if (event) this.setProduct({ event, product, index, productType: "fixedPeriodProducts" });
    },

    checkPositiveNumber(v) {
      if (v > 0 || v == "") return true;
      else return false;
    },

    checkGreaterThanZero(v) {
      if (v > 0) return true;
      else return false;
    },

    allowedDates(givenMonth) {
      const invoiceInterval = this.currentInvoice.invoiceInterval;

      // first month when available
      const nextDueDate = moment(this.currentInvoice.nextDueDate).format("YYYY-MM");

      const timeDiff = moment(givenMonth).diff(moment(nextDueDate), "months", true);
      const reminder = timeDiff % invoiceInterval;

      return moment(givenMonth).isSameOrAfter(nextDueDate) && reminder == 0 ? true : false;
    },
  },
};
</script>

<style scoped>
.delete-btn {
  cursor: pointer;
}

.details-container {
  display: flex;
}

.invoice-overview {
  padding: 20px;
  width: 100%;
  border: 2px solid var(--v-success-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.invoice-overview p {
  padding: 0;
  margin: 0;
}
</style>
