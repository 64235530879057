<template>
  <v-dialog v-model="dialog" fullscreen persistent>
    <v-card>
      <v-container fluid>
        <v-toolbar flat>
          <div>
            <v-card-title v-if="currentInvoice.invoiceNumber"
              >Muokkaa toistuvaislaskua {{ currentInvoice.invoiceNumber }}</v-card-title
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Loader -->
        <full-page-loader
          v-if="loading"
          text="Ladataan..."
          class="full-page-loader"
        ></full-page-loader>
      </v-container>

      <v-container v-if="!loading">
        <v-form ref="form">
          <div v-if="currentInvoice.active">
            <v-row dense>
              <v-col cols="12">
                <h3 class="mb-1">
                  Toistuvat laskut
                  <span v-if="this.currentInvoice.activeRentIncreaseInUse"
                    >vuokrankorotuksen jälkeen</span
                  >
                </h3>
              </v-col></v-row
            >
            <v-row dense align="center">
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-menu
                  ref="nextDueDateMenu"
                  v-model="nextDueDateMenu"
                  :close-on-content-click="false"
                  min-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormattedNextDueDate"
                      v-bind="attrs"
                      outlined
                      dense
                      v-on="on"
                      append-icon="mdi-calendar"
                      label="Seuraava eräpäivä"
                      v-prevent-manual-input
                      :rules="validations.required"
                      hide-details
                      @click="setDueDateWatcher(true)"
                    >
                      <template v-if="rentIncreaseDueDateAlert" v-slot:prepend>
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="warning" v-bind="attrs" v-on="on"
                              >mdi-alert-circle</v-icon
                            >
                          </template>
                          <span
                            >Korotetut tuotteet on aktivoitu laskulle, mutta olet aikaistanut
                            seuraavaa eräpäivää. Voit lähettää korotetut tuotteet vasta
                            {{ possibleRentIncreaseDueDateMonth }} alkaen.</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="nextDueDate"
                    :allowed-dates="allowedDueDates"
                    first-day-of-week="1"
                    @input="nextDueDateMenu = false"
                    @change="changeStartDate(false)"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="1" class="pr-2">
                <v-select
                  v-model="paymentTerm"
                  outlined
                  dense
                  :items="paymentTerms"
                  item-text="text"
                  item-value="days"
                  label="Maksuehto"
                  suffix="pv"
                  :rules="validations.required"
                  hide-details
                  @click="setDueDateWatcher(true)"
                  @change="changeStartDate(true)"
                >
                  <template v-if="paymentTermAlert" v-slot:prepend>
                    <v-tooltip bottom max-width="400">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                      </template>
                      <span
                        >Seuraavan laskun ja korotetun laskun lähetyspäivissä pitää olla vähintään 2
                        päivän ero. Pidennä toistuvien laskujen maksuehtoa, siirrä seuraavan laskun
                        eräpäivää tai muuta toistuvaa eräpäivää.</span
                      >
                    </v-tooltip>
                  </template></v-select
                >
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-select
                  v-model="otherDueDates"
                  outlined
                  dense
                  :items="dueDates"
                  item-text="text"
                  item-value="date"
                  label="Toistuvat eräpäivät seuraavan jälkeen"
                  :rules="validations.required"
                  hide-details
                  @click="setDueDateWatcher(true)"
                  @change="changeStartDate(true)"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-select
                  v-model="invoiceInterval"
                  outlined
                  dense
                  :items="invoiceIntervals"
                  item-text="text"
                  item-value="months"
                  label="Toistuvuusväli seuraavan jälkeen"
                  :rules="validations.required"
                  hide-details
                  @click="setDueDateWatcher(true)"
                  @change="changeStartDate(true)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-select
                  v-model="autoSendType"
                  outlined
                  dense
                  :items="sendTypes"
                  item-value="val"
                  item-text="text"
                  label="Toistuvien laskujen lähetystapa"
                  :rules="validations.required"
                  hide-details
                  @change="handleSendTypeChange"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <div v-if="currentInvoice.isRecurrent">
                  <label>Toistuvien laskujen kirjauspvm.</label>
                  <v-radio-group
                    v-model="billDateAsDueDate"
                    :mandatory="true"
                    style="margin-top: 0px"
                    hide-details
                    :disabled="showBasedOnService({ ropoCapital: false, talenom: true })"
                  >
                    <v-radio label="Eräpäiväkuukauden 1. päivä" :value="true"></v-radio>
                    <v-radio
                      label="Luomis-/lähetyspäivä (maksuehdon mukainen)"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-text-field
                  v-model="yourCode"
                  outlined
                  dense
                  label="Ostajan viite"
                  hide-details
                ></v-text-field>
                <small>Näkyy laskulla "Viitteenne"</small>
              </v-col>
            </v-row>

            <!-- <v-divider class="mx-1 mb-1"></v-divider> -->

            <!-- RENT INCREASE -->
            <rent-increase-invoice
              ref="rentIncreaseComponent"
              v-if="currentInvoice.currentRentIncrease"
              class="mb-2"
              :paymentTermAlert="paymentTermAlert"
              :rentIncreaseDueDateAlert="rentIncreaseDueDateAlert"
              :sendTypeAlert="sendTypeAlert"
            ></rent-increase-invoice>

            <!-- Details -->
            <v-row class="mt-1" dense>
              <v-col cols="12" sm="10" md="6" lg="5">
                <div class="invoice-overview">
                  <h3 class="mb-1">Laskun lähetys</h3>
                  <p>
                    Seuraavan laskun eräpäivä on
                    <span class="contract-text">{{ formatDate(currentInvoice.nextDueDate) }} </span>
                    <span v-if="rentIncreaseDueDateAlert">
                      <v-tooltip bottom max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="warning" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                        </template>
                        <span
                          >Korotetut tuotteet on aktivoitu laskulle, mutta olet aikaistanut
                          seuraavaa eräpäivää. Voit lähettää korotetut tuotteet vasta
                          {{ possibleRentIncreaseDueDateMonth }} alkaen.</span
                        >
                      </v-tooltip>
                    </span>
                  </p>
                  <p v-if="nextRecurrentBillDate">
                    Seuraavan laskun pvm ja lähetyspäivä on
                    <span class="contract-text">
                      {{ formatDate(nextRecurrentBillDate) }}
                      <span v-if="paymentTermAlert">
                        <v-tooltip bottom max-width="400">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon color="warning" v-bind="attrs" v-on="on"
                              >mdi-alert-circle</v-icon
                            >
                          </template>
                          <span
                            >Seuraavan laskun ja korotetun laskun lähetyspäivissä pitää olla
                            vähintään 2 päivän ero. Pidennä toistuvien laskujen maksuehtoa, siirrä
                            seuraavan laskun eräpäivää tai muuta toistuvaa eräpäivää.</span
                          >
                        </v-tooltip>
                      </span>
                    </span>
                  </p>
                  <v-divider class="mt-1 mb-1"></v-divider>
                  <p v-if="lastInvoiceDateExists">
                    Sitä seuraavan toistuvan laskun eräpäivä on
                    <span class="contract-text">{{ formatDate(nextAfterNextDueDate) }}</span>
                  </p>

                  <p>
                    Toistuvien laskujen lähetystapa on
                    <span class="contract-text">{{
                      formatSendType(currentInvoice.autoSendType)
                    }}</span>
                  </p>
                  <v-divider v-if="currentInvoice.lastInvoiceDate" class="mt-1 mb-1"></v-divider>
                  <p v-if="currentInvoice.lastInvoiceDate">
                    Sopimus päättyy
                    <span class="contract-text error--text">{{
                      formatDate(currentInvoice.lastInvoiceDate)
                    }}</span>
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-divider class="mt-2 mb-2"></v-divider>
                <h3 class="mt-2 mb-1">Laskutustiedot</h3>
              </v-col></v-row
            >
            <v-row dense>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-select
                  v-model="principalTenant"
                  outlined
                  dense
                  :items="invoiceReceivers"
                  :item-text="invoiceHelpers.getPrincipalTenantNameInfo"
                  item-value="tenantId._id"
                  label="Laskun saaja"
                  return-object
                  :rules="validations.required"
                  @change="updateReceiverInfo($event)"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-text-field
                  v-model="tenantName"
                  label="Laskun saajan nimi"
                  outlined
                  dense
                  :rules="validations.required"
                ></v-text-field>
              </v-col>
              <v-col
                v-if="
                  currentInvoice.autoSendType == 'email' ||
                  (showFieldsForNextSendType &&
                    currentInvoice.currentRentIncrease.sendType == 'email')
                "
                cols="12"
                md="4"
                lg="3"
                xl="2"
                class="pr-2"
              >
                <v-text-field
                  v-model="tenantEmail"
                  label="Laskun saajan email"
                  outlined
                  dense
                  :rules="validations.required.concat(validations.email)"
                ></v-text-field>
              </v-col>

              <v-col
                v-if="
                  showBasedOnService({ ropoCapital: true, talenom: false }) &&
                  (currentInvoice.autoSendType == 'email' ||
                    (showFieldsForNextSendType &&
                      currentInvoice.currentRentIncrease.sendType == 'email'))
                "
                cols="12"
                md="4"
                lg="3"
                xl="2"
                class="pr-2"
              >
                <v-text-field
                  v-model="tenantEmail2"
                  label="Laskunsaajan email 2"
                  outlined
                  dense
                  :rules="validations.email2"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- Evoice -->
            <v-row dense>
              <v-col
                v-if="
                  currentInvoice.autoSendType == `evoice` ||
                  (showFieldsForNextSendType &&
                    currentInvoice.currentRentIncrease.sendType == 'evoice')
                "
                cols="12"
                md="6"
                lg="4"
                xl="3"
              >
                <v-btn small class="mb-1 mt-1" color="primary" @click="getTenantEvoiceInformation"
                  >Hae verkkolaskutiedot</v-btn
                >
              </v-col>
            </v-row>

            <v-row
              v-if="
                currentInvoice.autoSendType == `evoice` ||
                (showFieldsForNextSendType &&
                  currentInvoice.currentRentIncrease.sendType == 'evoice')
              "
              dense
            >
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="evoiceAddress"
                  label="Verkkolaskuosoite"
                  outlined
                  dense
                  :rules="validations.required"
                  :disabled="contactHasServiceId(principalTenant)"
                  :persistent-hint="true"
                  :hint="
                    contactHasServiceId(principalTenant)
                      ? 'Vaihda laskun verkkolaskuosoite muokkaamalla vuokralaista'
                      : ''
                  "
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="evoiceId"
                  label="Operaattoritunnus"
                  placeholder="esim. BAWCFI22"
                  outlined
                  dense
                  :rules="validations.required"
                  :disabled="contactHasServiceId(principalTenant)"
                  :persistent-hint="true"
                  :hint="
                    contactHasServiceId(principalTenant)
                      ? 'Vaihda laskun operaattoritunnus muokkaamalla vuokralaista'
                      : ''
                  "
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  v-model="evoiceOperator"
                  label="Välittäjä"
                  placeholder="Esim. Basware Oyj"
                  outlined
                  dense
                  :rules="validations.required"
                  :disabled="contactHasServiceId(principalTenant)"
                  :persistent-hint="true"
                  :hint="
                    contactHasServiceId(principalTenant)
                      ? 'Vaihda välittäjä muokkaamalla vuokralaista'
                      : ''
                  "
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- <v-divider class="mx-1 mt-1 mb-1"></v-divider> -->

            <!-- Billing address -->
            <v-row dense>
              <v-col cols="12">
                <v-divider class="mt-1 mb-2"></v-divider>
                <h3 class="mt-1">Laskutusosoite</h3>
              </v-col></v-row
            >
            <v-row dense>
              <v-col cols="6" md="6" lg="3" xl="2" class="pr-2">
                <label>Laskun lähetys</label>
                <v-radio-group
                  v-model="sendToApartmentAddress"
                  :mandatory="true"
                  @change="setSendingAddress"
                  style="margin-top: 0px"
                  hide-details
                >
                  <v-radio
                    label="Lasku lähetetään vuokrakohteen osoitetiedoilla"
                    :value="true"
                  ></v-radio>
                  <v-radio label="Lasku lähetetään muilla osoitetiedoilla" :value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col v-if="!sendToApartmentAddress" cols="6" md="6" lg="4" xl="3" class="pr-2">
                <v-btn small class="mb-2" color="primary" @click="setSendingAddress"
                  >Hae vuokralaisen osoite</v-btn
                >
              </v-col>
            </v-row>

            <v-row v-if="!sendToApartmentAddress" dense
              ><v-col cols="12" sm="6" md="4" lg="3">
                <v-checkbox
                  v-model="addExtraLine"
                  style="margin-top: 0px"
                  label="Lisää uusi osoiterivi (esim. Docuscan)"
                  hide-details
                  @change="addressLine2 = null"
                ></v-checkbox> </v-col
            ></v-row>

            <v-row dense>
              <v-col v-if="addExtraLine" cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-text-field
                  v-model="addressLine2"
                  label="Osoiterivi 1"
                  placeholder="Esim. OVT 12345678"
                  outlined
                  dense
                  hide-details
                  :rules="validations.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-text-field
                  v-model="sendingAddress"
                  :label="addExtraLine ? 'Osoiterivi 2' : 'Osoite'"
                  placeholder="Esim. Testikatu 5 A 7"
                  outlined
                  dense
                  hide-details
                  :rules="validations.required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-text-field
                  v-model="sendingZipCode"
                  label="Postinumero"
                  outlined
                  dense
                  :rules="validations.required"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-text-field
                  v-model="sendingCity"
                  label="Kaupunki"
                  outlined
                  dense
                  :rules="validations.required"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pr-2">
                <v-autocomplete
                  v-model="sendingCountry"
                  :items="getCountries()"
                  item-text="text"
                  item-value="code"
                  clearable
                  outlined
                  label="Maa"
                  dense
                  :rules="[
                    isRequiredByInvoiceService({ ropoCapital: false, talenom: true }),
                    isInvoiceAddressCountryAvailable,
                  ]"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <div
              :style="{
                opacity:
                  !currentInvoice.fixedPeriodRecurrency.inUse ||
                  (currentInvoice.fixedPeriodRecurrency.inUse && fixedPeriodStartInFuture)
                    ? '1'
                    : '0.5',
                'pointer-events':
                  !currentInvoice.fixedPeriodRecurrency.inUse ||
                  (currentInvoice.fixedPeriodRecurrency.inUse && fixedPeriodStartInFuture)
                    ? 'auto'
                    : 'none',
              }"
            >
              <!-- Products  -->
              <v-row dense>
                <v-col cols="12">
                  <v-divider class="mb-2"></v-divider>
                  <h3 class="mt-1">Tuotteet</h3>
                </v-col>
              </v-row>

              <div v-for="(product, index) in currentInvoice.products" :key="index">
                <v-row dense class="mb-1">
                  <v-col cols="6" md="3" lg="2">
                    <label>Valitse tuote</label>
                    <v-autocomplete
                      :value="product.productId"
                      :items="products"
                      item-text="desc"
                      item-value="_id"
                      dense
                      outlined
                      return-object
                      small-chips
                      @focus="getInvoiceProducts"
                      @change="setProductToState($event, { ...product }, index)"
                      hide-details
                      :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="6" md="2" lg="2" class="pr-1">
                    <v-text-field
                      v-model.number="product.desc"
                      outlined
                      dense
                      label="Kuvaus"
                      hide-details
                      :rules="validations.required"
                    />
                  </v-col>
                  <v-col cols="6" md="2" lg="2" class="pr-1">
                    <v-text-field
                      v-model.number="product.amount"
                      outlined
                      dense
                      step="0.01"
                      type="number"
                      label="Hinta"
                      suffix="€"
                      hide-details
                      :rules="validations.required.concat(validations.positiveNumber)"
                    />
                  </v-col>

                  <v-col cols="6" md="2" lg="1" class="pr-1">
                    <v-text-field
                      v-model.number="product.count"
                      outlined
                      dense
                      v-only-numbers
                      v-prevent-paste
                      label="Määrä"
                      hide-details
                      :rules="validations.required.concat(validations.positiveNumber)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="6" md="2" lg="2" xl="1" class="pr-1">
                    <v-select
                      v-model="product.itemtype"
                      outlined
                      dense
                      :items="productItemTypes"
                      label="Tuotetyyppi"
                      return-object
                      hide-details
                      :rules="validations.required"
                    ></v-select>
                  </v-col>

                  <v-col cols="6" md="3" lg="2">
                    <div class="details-container">
                      <v-select
                        v-model.number="product.taxpr"
                        outlined
                        dense
                        :items="vatTypes"
                        item-value="val"
                        item-text="text"
                        label="Alv-kanta"
                        hide-details
                        :rules="validations.zeroOrGreater"
                      ></v-select>
                      <p
                        class="ml-4 mt-1 delete-btn error--text"
                        text
                        @click="deleteProduct({ index, productType: 'products' })"
                      >
                        X
                      </p>
                    </div>
                  </v-col>
                </v-row>

                <div v-if="product.accountingId" style="margin-top: 5px">
                  <p style="font-size: 13px">Tiliöintikoodi: {{ product.accountingId }}</p>
                  <v-divider class="mb-1 mt-2"></v-divider>
                </div>

                <v-divider v-else class="mt-2 mb-1"></v-divider>
              </div>

              <div
                v-show="
                  !currentInvoice.fixedPeriodRecurrency.inUse ||
                  (currentInvoice.fixedPeriodRecurrency.inUse && fixedPeriodStartInFuture)
                "
              >
                <v-row class="mb-2" :class="{ 'mt-2': currentInvoice.products.length > 0 }" dense>
                  <v-col cols="12">
                    <v-btn color="primary" @click="addProduct('products')">Lisää tuoterivi</v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="isCompany && foundVatFreeProducts" class="mb-2" dense>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <label style="margin: 0px">Alv 0 % selite</label>
                    <v-text-field v-model="vatFreeText" outlined dense hide-details></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <!-- VATS -->
              <vat-component
                v-if="currentInvoice.products.length > 0"
                class="mb-3"
                :products="currentInvoice.products"
              ></vat-component>

              <v-divider class="mb-1"></v-divider>
            </div>

            <!-- FIXED PERIOD COMPONENT -->
            <fixed-period-component></fixed-period-component>

            <v-divider class="mb-1"></v-divider>

            <!-- Reminders -->
            <v-row dense>
              <v-col cols="12">
                <h3>Maksumuistutukset</h3>
              </v-col></v-row
            >
            <v-row dense>
              <v-col cols="12" md="5" lg="4">
                <small
                  >Jos kytket automaattiset maksumuistutukset pois päältä, Ropo Capitalin mitkään
                  muistutus- tai perintäpalvelut eivät ole toistuvien laskujen osalta käytössäsi.
                </small>
                <v-checkbox
                  style="margin-top: 3px"
                  v-model="recurrentInvReminder"
                  label="Automaattiset maksumuistutukset"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-divider class="mt-1 mb-1"></v-divider>

            <!-- Outgoing payments -->
            <div v-if="active">
              <v-row dense>
                <v-col cols="10" sm="6" md="4" lg="3" xl="2">
                  <h3 class="mb-1">Laskun kieli</h3>
                  <v-select
                    v-model="language"
                    item-text="text"
                    item-value="val"
                    :items="getServiceInvoiceLanguages()"
                    outlined
                    dense
                    label="Laskun kieli"
                    :disabled="contactHasServiceId(principalTenant)"
                    :persistent-hint="true"
                    :hint="
                      contactHasServiceId(principalTenant)
                        ? 'Vaihda laskun kieli muokkaamalla vuokralaista'
                        : ''
                    "
                    :rules="validations.required"
                  ></v-select>
                </v-col>

                <v-col
                  v-if="currentUser.currentAccount.settings.outgoingPayments"
                  cols="12"
                  sm="6"
                  md="5"
                  lg="4"
                  xl="3"
                >
                  <h3 class="mb-1">Lähtevien maksujen seuranta</h3>
                  <v-checkbox
                    v-model="outgoingPaymentsInUse"
                    style="margin-top: 0px; padding-top: 0px"
                    label="Luo toimeksiantajalle lähtevä maksu automaattisesti"
                    hide-details
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" class="pl-lg-3">
                  <CostCentre :invoice="currentInvoice" :editRecurrentInvoice="true"></CostCentre>
                </v-col>
              </v-row>

              <v-divider class="mt-1 mb-1"></v-divider>
            </div>
          </div>

          <v-row dense>
            <v-col cols="12" md="4">
              <h3>Tila</h3>
              <v-switch
                v-model="active"
                style="margin-top: 0px"
                label="Aktiivinen"
                hide-details
              ></v-switch>
            </v-col>
          </v-row>

          <v-divider class="mb-2"></v-divider>

          <h3 v-if="!currentInvoice.active" class="text-center mb-2 error--text">
            Lasku asetetaan tallennettaessa ei aktiiviseksi. Laskua ei voi tämän jälkeen enää
            muokata.
          </h3>
        </v-form>

        <v-card-actions class="pa-0">
          <v-btn color="error" outlined @click="dialog = false"> Sulje </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" @click="saveInvoice">Tallenna</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>

    <!-- Dialog change startDate -->
    <v-dialog v-model="showChangeStartDateDialog" persistent width="500">
      <v-card>
        <v-card-title
          >Haluatko muuttaa määräaikaisten tuotteiden aloituspäivän myös seuraavaa eräpäivää
          vastaavaan kuukauteen?</v-card-title
        >

        <v-card-actions>
          <v-btn small color="error" outlined @click="changeStartDateBackwards(false)">En</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="changeStartDateBackwards(true)"
            >Kyllä</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import CostCentre from "./CostCentre.vue";
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import FixedPeriodComponent from "./FixedPeriodComponent.vue";
import RentIncreaseInvoice from "./RentIncreaseInvoice.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import helpers from "@/utils/rentIncreaseHelpers";
import _ from "lodash";
import moment from "moment";
import globalValues from "../../configs/globalValues";
import invoiceHelpers from "@/utils/invoiceHelpers";
import FullPageLoader from "@/components/FullPageLoader";
import validations from "@/validations";
import VatComponent from "@/components/Invoice/VatComponent";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    FixedPeriodComponent,
    RentIncreaseInvoice,
    CostCentre,
    FullPageLoader,
    VatComponent,
  },

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      nextDueDateMenu: false,
      fixedPeriodDialog: false,
      showChangeStartDateDialog: false,
      invoiceIntervals: globalValues.invoiceIntervals,
      dueDates: globalValues.dueDates,
      months: globalValues.months,
      vatTypes: globalValues.vatTypes,
      productItemTypes: globalValues.productItemTypes,
      validations,
      invoiceHelpers,
    };
  },

  computed: {
    ...mapState("invoice", [
      "currentInvoice",
      "recurrentInvoice",
      "rentIncrease",
      "rentIncreaseBillDate",
      "rentIncreaseDueDate",
      "loading",
    ]),
    ...mapState("product", ["products"]),
    ...mapState("account", ["currentUser"]),
    ...mapGetters("invoice", ["fixedPeriodStartInFuture"]),

    principalTenant: {
      get() {
        return this.$store.state.invoice.principalTenant;
      },
      set(val) {
        this.setPrincipalTenant(val);
      },
    },
    nextDueDate: {
      get() {
        return this.currentInvoice.nextDueDate;
      },
      set(val) {
        this.setFormField({ val, field: "nextDueDate" });
      },
    },
    paymentTerm: {
      get() {
        return this.currentInvoice.paymentTerm;
      },
      set(val) {
        this.setFormField({ val, field: "paymentTerm" });
      },
    },
    otherDueDates: {
      get() {
        return this.currentInvoice.otherDueDates;
      },
      set(val) {
        this.setFormField({ val, field: "otherDueDates" });
      },
    },
    invoiceInterval: {
      get() {
        return this.currentInvoice.invoiceInterval;
      },
      set(val) {
        this.setFormField({ val, field: "invoiceInterval" });
      },
    },
    autoSendType: {
      get() {
        return this.currentInvoice.autoSendType;
      },
      set(val) {
        this.setFormField({ val, field: "autoSendType" });
      },
    },
    billDateAsDueDate: {
      get() {
        return this.currentInvoice.billDateAsDueDate;
      },
      set(val) {
        this.setFormField({ val, field: "billDateAsDueDate" });
      },
    },
    tenantName: {
      get() {
        return this.currentInvoice.tenant.name;
      },
      set(val) {
        this.setFormField({ val, field: "tenant.name" });
      },
    },
    tenantEmail: {
      get() {
        return this.currentInvoice.tenant.email;
      },
      set(val) {
        this.setFormField({ val, field: "tenant.email" });
      },
    },
    tenantEmail2: {
      get() {
        return this.currentInvoice.tenant.email2;
      },
      set(val) {
        this.setFormField({ val, field: "tenant.email2" });
      },
    },
    evoiceOperator: {
      get() {
        return this.currentInvoice.evoice.evoiceOperator;
      },
      set(val) {
        this.setFormField({ val, field: "evoice.evoiceOperator" });
      },
    },
    evoiceId: {
      get() {
        return this.currentInvoice.evoice.evoiceId;
      },
      set(val) {
        this.setFormField({ val, field: "evoice.evoiceId" });
      },
    },
    evoiceAddress: {
      get() {
        return this.currentInvoice.evoice.evoiceAddress;
      },
      set(val) {
        this.setFormField({ val, field: "evoice.evoiceAddress" });
      },
    },
    yourCode: {
      get() {
        return this.currentInvoice.yourCode;
      },
      set(val) {
        this.setFormField({ val, field: "yourCode" });
      },
    },
    sendToApartmentAddress: {
      get() {
        return this.currentInvoice.sendToApartmentAddress;
      },
      set(val) {
        this.setFormField({ val, field: "sendToApartmentAddress" });
      },
    },
    addExtraLine: {
      get() {
        return this.currentInvoice.sendingAddress.addExtraLine;
      },
      set(val) {
        this.setFormField({ val, field: "sendingAddress.addExtraLine" });
      },
    },
    sendingAddress: {
      get() {
        return this.currentInvoice.sendingAddress.address;
      },
      set(val) {
        this.setFormField({ val, field: "sendingAddress.address" });
      },
    },
    addressLine2: {
      get() {
        return this.currentInvoice.sendingAddress.addressLine2;
      },
      set(val) {
        this.setFormField({ val, field: "sendingAddress.addressLine2" });
      },
    },
    sendingZipCode: {
      get() {
        return this.currentInvoice.sendingAddress.zipCode;
      },
      set(val) {
        this.setFormField({ val, field: "sendingAddress.zipCode" });
      },
    },
    sendingCity: {
      get() {
        return this.currentInvoice.sendingAddress.city;
      },
      set(val) {
        this.setFormField({ val, field: "sendingAddress.city" });
      },
    },
    sendingCountry: {
      get() {
        return this.currentInvoice.sendingAddress.country;
      },
      set(val) {
        this.setFormField({ val, field: "sendingAddress.country" });
      },
    },
    recurrentInvReminder: {
      get() {
        return this.currentInvoice.recurrentInvReminder;
      },
      set(val) {
        this.setFormField({ val, field: "recurrentInvReminder" });
      },
    },
    outgoingPaymentsInUse: {
      get() {
        return this.currentInvoice.outgoingPayments.inUse;
      },
      set(val) {
        this.setFormField({ val, field: "outgoingPayments.inUse" });
      },
    },
    language: {
      get() {
        return this.currentInvoice.language;
      },
      set(val) {
        this.setFormField({ val, field: "language" });
      },
    },
    vatFreeText: {
      get() {
        return this.currentInvoice.vatFreeText;
      },
      set(val) {
        this.setFormField({ val, field: "vatFreeText" });
      },
    },
    active: {
      get() {
        return this.currentInvoice.active;
      },
      set(val) {
        this.setFormField({ val, field: "active" });
      },
    },

    invoiceReceivers() {
      if (this.currentInvoice.contract.tenant && this.currentInvoice.contract.otherTenants) {
        return invoiceHelpers.getInvoiceReceivers(this.currentInvoice.contract);
      } else {
        return [];
      }
    },

    nextAfterNextDueDate() {
      if (
        !this.currentInvoice.nextDueDate ||
        !this.currentInvoice.otherDueDates ||
        !this.currentInvoice.invoiceInterval
      ) {
        return;
      }

      let dueDateNext = new Date(this.currentInvoice.nextDueDate);

      if (this.currentInvoice.otherDueDates != 100) {
        // Days 1 to 28
        return moment(
          new Date(
            dueDateNext.getFullYear(),
            dueDateNext.getMonth() + this.currentInvoice.invoiceInterval,
            this.currentInvoice.otherDueDates
          )
        ).format("YYYY-MM-DD");
      } else {
        // Last day of the month
        const lastDate = new Date(
          dueDateNext.getFullYear(),
          dueDateNext.getMonth() + this.currentInvoice.invoiceInterval + 1,
          0
        ).getDate();

        return moment(
          new Date(
            dueDateNext.getFullYear(),
            dueDateNext.getMonth() + this.currentInvoice.invoiceInterval,
            lastDate
          )
        ).format("YYYY-MM-DD");
      }
    },

    nextRecurrentBillDate() {
      if (this.currentInvoice.nextDueDate) {
        let nextDueDate = moment(this.currentInvoice.nextDueDate);

        let nextBillDate;
        if (this.currentInvoice.activeRentIncreaseInUse) {
          nextBillDate = nextDueDate
            .subtract(this.currentInvoice.nextPaymentTerm, "days")
            .format("YYYY-MM-DD");
        } else {
          nextBillDate = nextDueDate
            .subtract(this.currentInvoice.paymentTerm, "days")
            .format("YYYY-MM-DD");
        }

        if (moment(moment().format("YYYY-MM-DD")).isSameOrAfter(nextBillDate)) {
          return moment().add(1, "days").format("YYYY-MM-DD");
        } else return nextBillDate;
      }
      return null;
    },

    lastInvoiceDateExists() {
      if (!this.currentInvoice.lastInvoiceDate) return true;
      else {
        const lastInvoiceYear = new Date(this.currentInvoice.lastInvoiceDate).getFullYear();
        const lastInvoiceMonth = new Date(this.currentInvoice.lastInvoiceDate).getMonth();

        if (
          new Date(lastInvoiceYear, lastInvoiceMonth).getTime() >=
          new Date(
            new Date(this.nextAfterNextDueDate).getFullYear(),
            new Date(this.nextAfterNextDueDate).getMonth()
          ).getTime()
        ) {
          return true;
        } else return false;
      }
    },

    possibleRentIncreaseDueDateMonth() {
      const monthNum = new Date(this.currentInvoice.currentRentIncrease.originalDueDate).getMonth();
      const month = this.months[monthNum];
      const year = new Date(this.currentInvoice.currentRentIncrease.originalDueDate).getFullYear();
      return `${month} ${year}`;
    },

    paymentTermAlert() {
      if (!this.currentInvoice.currentRentIncrease) return false;

      if (
        moment(this.nextDueDate).isBefore(this.rentIncreaseDueDate) &&
        moment(moment(this.rentIncreaseBillDate).subtract(1, "days")).isSameOrBefore(
          this.nextRecurrentBillDate
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    rentIncreaseDueDateAlert() {
      if (!this.currentInvoice.currentRentIncrease) return false;

      if (!this.currentInvoice.currentRentIncrease.activated) return false;

      if (this.nextDueDate && this.rentIncreaseDueDate) {
        const nextDueDate = moment(this.nextDueDate).format("YYYY-MM");
        const nextRentIncreaseDueDate = moment(this.rentIncreaseDueDate).format("YYYY-MM");
        if (moment(nextDueDate).isBefore(nextRentIncreaseDueDate)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    sendTypeAlert() {
      let alert = false;
      if (!this.currentInvoice.currentRentIncrease || !this.currentInvoice.nextSendType)
        return alert;

      // Email
      if (
        this.currentInvoice.nextSendType == "email" &&
        (!this.currentInvoice.tenant.email ||
          this.currentInvoice.tenant.email == "" ||
          !this.validations.isValidEmail(this.currentInvoice.tenant.email))
      ) {
        alert = true;
      }

      // Evoice
      if (
        this.currentInvoice.nextSendType == "evoice" &&
        (!this.currentInvoice.evoice.evoiceId ||
          this.currentInvoice.evoice.evoiceAddress == "" ||
          !this.currentInvoice.evoice.evoiceAddress ||
          this.currentInvoice.evoice.evoiceAddress == "")
      ) {
        alert = true;
      }

      return alert;
    },

    showFieldsForNextSendType() {
      if (!this.currentInvoice.currentRentIncrease) return false;
      if (this.currentInvoice.currentRentIncrease.sendType == "email") {
        return true;
      }
      if (this.currentInvoice.currentRentIncrease.sendType == "evoice") {
        return true;
      }
      return false;
    },

    foundVatFreeProducts() {
      let boolean = false;
      this.currentInvoice.products.forEach((el) => {
        if (el.taxpr === 0) boolean = true;
      });

      return boolean;
    },

    isCompany() {
      return this.$store.state.account.currentUser.currentAccount.isCompany;
    },

    dateFormattedNextDueDate() {
      return this.formatDate(this.$store.state.invoice.currentInvoice.nextDueDate);
    },

    paymentTerms() {
      return globalValues.paymentTerms(this.currentInvoice.invoiceInterval);
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    sendTypes() {
      return globalValues.sendTypes(this.principalTenant.tenantId.isCompany, this.isCompany);
    },
  },

  watch: {
    recurrentInvoice: {
      deep: true,
      handler(val) {
        this.setRecurrentInvoice(_.cloneDeep(val));

        const tenantClientId =
          this.currentInvoice?.updatedTenant?.clientId || this.currentInvoice.tenant.clientId;

        // Set principal tenant
        const tenant = invoiceHelpers.getInvoiceTenantByClientId(
          this.currentInvoice.contract,
          tenantClientId
        );

        this.principalTenant = tenant;

        // Set rentIncrease duedate
        if (this.currentInvoice.currentRentIncrease) {
          this.setDueDateWatcher(false);

          const rentIncreaseDueDate = helpers.getInvoiceDueDate(
            this.currentInvoice,
            this.currentInvoice.currentRentIncrease.originalDueDate
          );

          this.setRentIncreaseDates({
            field: "rentIncreaseDueDate",
            val: rentIncreaseDueDate,
          });

          this.setRentIncreaseDates({
            field: "rentIncreaseBillDate",
            val: helpers.calcRentIncreaseBillDate(this.currentInvoice, rentIncreaseDueDate),
          });
        }

        this.setLoading(false);
      },
    },

    "currentInvoice.invoiceInterval": {
      deep: true,
      handler(newVal) {
        if (newVal == 1 && this.currentInvoice.paymentTerm > 28) {
          this.currentInvoice.paymentTerm = 14;
          this.showPopup("Maksuehtoa muutettu", "info");
        }
      },
    },
  },

  methods: {
    ...mapActions("invoice", ["updateRecurrentInvoice"]),
    ...mapMutations("invoice", [
      "removeInvoice",
      "setFormField",
      "addProduct",
      "setProduct",
      "deleteProduct",
      "setRentIncreaseDates",
      "setDueDateWatcher",
      "setPrincipalTenant",
      "setRecurrentInvoice",
      "setRecurrentInvoiceToState",
      "setLoading",
    ]),
    ...mapActions("product", ["getInvoiceProducts"]),

    // Called from invoices component when click edit btn
    init(invoice) {
      this.setLoading(true);
      this.setRecurrentInvoiceToState(invoice);
      this.getInvoiceProducts();
    },

    async saveInvoice() {
      if (this.$refs.form.validate()) {
        try {
          if (this.currentInvoice.products.length == 0) {
            return this.showPopup("Lisää ainakin yksi tuoterivi", "error");
          }

          if (this.active) {
            // check if nextDueDate is less or equal than today
            if (
              new Date(this.currentInvoice.nextDueDate).getTime() <
              new Date(moment(Date.now()).format("YYYY-MM-DD")).getTime()
            ) {
              return this.showPopup(
                "Et voi asettaa tulevan laskun eräpäivää menneisyyteen. Muuta toistuvaa eräpäivää.",
                "error"
              );
            }
            if (this.paymentTermAlert) {
              return this.showPopup(
                "Seuraavan lasku ja korotetun laskun eräpäivät ovat liian lähellä toisiaan. Eron on oltava vähintään 2 päivää. Tarkasta maksuehdon huomio!",
                "error"
              );
            }
            if (this.rentIncreaseDueDateAlert) {
              return this.showPopup(
                "Et voi lähettää korotettuja tuotteita ennen vuokrankorotukselle määriteltyä eräpäivää. Tarkista huomio vuokrankorotus aktivoitu kohdan eräpäivästä!",
                "error"
              );
            }
          }

          this.setLoading(true);

          const measurementsNeeded = this.currentInvoice.measurementsNeeded;

          // Save invoice
          await this.updateRecurrentInvoice({
            invoice: this.currentInvoice,
            principalTenant: this.principalTenant,
          });

          // Delete from list
          if (measurementsNeeded) {
            this.removeInvoice(this.currentInvoice._id);
          }

          if (!this.currentInvoice.active) this.dialog = false;

          this.setLoading(false);
        } catch (err) {
          this.setLoading(false);
          this.showPopup(err, "error");
        }
      } else {
        this.showPopup("Tarkista kaikki pakolliset kentät", "error");
      }
    },

    setProductToState(event, product, index) {
      if (event) this.setProduct({ event, product, index, productType: "products" });
    },

    changeStartDate(changeOtherDueDates) {
      const startDateMonth = moment(this.currentInvoice.fixedPeriodRecurrency.startDate).format(
        "YYYY-MM"
      );
      const nextDueDateMonth = moment(this.currentInvoice.nextDueDate).format("YYYY-MM");

      //  Change start date only if fixedPeriodRecurrency is NOT active and is in use
      if (
        this.currentInvoice.fixedPeriodRecurrency.inUse &&
        !this.currentInvoice.fixedPeriodRecurrency.active
      ) {
        // If dueDate month is greater than startDate, set startDate to nextDuedate
        if (moment(nextDueDateMonth).isAfter(startDateMonth)) {
          // Give notice popup
          if (startDateMonth != nextDueDateMonth) {
            this.showPopup(
              "Määräaikaisten tuotteiden ensimmäisen eräpäivän kuukautta siirrettiin.",
              "info"
            );
          }

          // SET FIELDS TO STATE
          this.setFormField({
            val: nextDueDateMonth,
            field: "fixedPeriodRecurrency.startDate",
          });
        }
        // If dueDate month is before startDate, set startDate if confirmed
        else if (moment(nextDueDateMonth).isBefore(startDateMonth)) {
          if (!changeOtherDueDates) {
            this.showChangeStartDateDialog = true;
          } else {
            if (startDateMonth != this.getNewPossibleStartDate(false)) {
              // Give notice popup
              this.showPopup(
                "Määräaikaisten tuotteiden ensimmäisen eräpäivän kuukautta siirrettiin.",
                "info"
              );
            }

            // SET FIELDS TO STATE
            this.setFormField({
              val: this.getNewPossibleStartDate(false),
              field: "fixedPeriodRecurrency.startDate",
            });
          }
        } else {
          // Same month, just fix the date
          // SET FIELDS TO STATE
          this.setFormField({
            val: nextDueDateMonth,
            field: "fixedPeriodRecurrency.startDate",
          });
        }
      }
    },

    changeStartDateBackwards(accept) {
      if (accept) {
        this.setFormField({
          val: moment(new Date(this.currentInvoice.nextDueDate))
            .startOf("month")
            .format("YYYY-MM-DD"),
          field: "fixedPeriodRecurrency.startDate",
        });

        this.showPopup(
          "Määräaikaisten tuotteiden ensimmäisen eräpäivän kuukautta siirrettiin.",
          "info"
        );
      } else {
        const newStartDate = this.getNewPossibleStartDate(true);

        // Give notice popup
        if (
          newStartDate !=
          moment(this.currentInvoice.fixedPeriodRecurrency.startDate).format("YYYY-MM")
        ) {
          this.showPopup(
            "Määräaikaisten tuotteiden ensimmäisen eräpäivän kuukautta siirrettiin.",
            "info"
          );
        }

        this.setFormField({
          val: newStartDate,
          field: "fixedPeriodRecurrency.startDate",
        });
      }

      this.showChangeStartDateDialog = false;
    },

    getNewPossibleStartDate(startFromStartDate) {
      const startDateMonth = moment(this.currentInvoice.fixedPeriodRecurrency.startDate).format(
        "YYYY-MM"
      );
      const nextDueDateMonth = moment(this.currentInvoice.nextDueDate).format("YYYY-MM");
      const invoiceInterval = this.currentInvoice.invoiceInterval;

      // first month when available
      const timeDiff = moment(startDateMonth).diff(moment(nextDueDateMonth), "months", true);
      const reminder = timeDiff % invoiceInterval;
      let newStartDate;

      if (reminder == 0) {
        newStartDate = startDateMonth;
      } else {
        let newPossibleDate = startFromStartDate ? startDateMonth : nextDueDateMonth;
        let count = 0;
        let reminder;
        // Search next possible date in future
        do {
          newPossibleDate = moment(newPossibleDate).add(1, "months").format("YYYY-MM");
          const timeDiff = moment(newPossibleDate).diff(moment(nextDueDateMonth), "months", true);
          reminder = timeDiff % invoiceInterval;
          count++;
        } while (reminder != 0 && count < 12);

        newStartDate = newPossibleDate;
      }

      return newStartDate;
    },

    updateReceiverInfo(tenant) {
      if (tenant) {
        this.currentInvoice.tenant.name = tenant.tenantId?.name || "";
        this.currentInvoice.tenant.email = tenant.tenantId?.email || "";

        if (!tenant.tenantId.isCompany) {
          this.currentInvoice.evoice.evoiceOperator = "";
          this.currentInvoice.evoice.evoiceId = "";
          this.currentInvoice.evoice.evoiceAddress = "";
          if (this.currentInvoice.autoSendType === "evoice") {
            this.currentInvoice.autoSendType = "print";
            this.showPopup(
              "Verkkolasku vaihdettu itsetulostukseksi. Vastaanottaja ei ole yritys.",
              "info"
            );
          }
        }
      }
    },

    handleSendTypeChange(type) {
      if (type === "evoice") this.getTenantEvoiceInformation();
    },

    getTenantEvoiceInformation() {
      this.evoiceOperator = this.principalTenant.tenantId.evoice.evoiceOperator;
      this.evoiceId = this.principalTenant.tenantId.evoice.evoiceId;
      this.evoiceAddress = this.principalTenant.tenantId.evoice.evoiceAddress;
    },

    setSendingAddress() {
      if (this.currentInvoice.sendToApartmentAddress) {
        this.addExtraLine = false;
        this.addressLine2 = null;
        const apartmentNumber = this.currentInvoice.contract.apartment.id.apartmentNumber
          ? this.currentInvoice.contract.apartment.id.apartmentNumber
          : "";

        // Set fields
        this.sendingAddress = `${this.currentInvoice.contract.apartment.id.address} ${apartmentNumber}`;
        this.sendingZipCode = this.currentInvoice.contract.apartment.id.zipCode;
        this.sendingCity = this.currentInvoice.contract.apartment.id.city;
        this.sendingCountry = this.currentInvoice.contract.apartment.id.country || null;
      } else {
        // Set fields
        this.sendingAddress = this.principalTenant.tenantId.address;
        this.sendingZipCode = this.principalTenant.tenantId.zipCode;
        this.sendingCity = this.principalTenant.tenantId.city;
        this.sendingCountry = this.principalTenant.tenantId.country || null;
      }
    },

    allowedDueDates(val) {
      const today = new Date(moment(new Date()).format("YYYY-MM-DD")).getTime();
      return new Date(val).getTime() > today;
    },
  },
};
</script>

<style scoped>
.contract-text {
  font-size: 14px;
  font-weight: 600;
  color: rgb(51, 51, 51);
}

.delete-btn {
  cursor: pointer;
}

.invoice-overview {
  padding: 20px;
  width: 100%;
  border: 2px solid var(--v-success-base);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.invoice-overview p {
  padding: 0;
  margin: 0;
}

.details-container {
  display: flex;
}
</style>
