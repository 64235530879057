<template>
  <!-- Dialog send invoice -->

  <v-dialog v-model="dialog" width="550" :persistent="loading">
    <v-card>
      <v-card-title>Laskun lähetys</v-card-title>

      <v-card-text>
        <!-- ERRORS -->
        <div v-if="billDateState === 'past'">
          <h4 class="warning--text mb-1">
            Ennen lähettämistä, siirrä laskun päivämäärä vähintään kuluvaan päivään.
          </h4>
        </div>

        <!-- FUTURE -->
        <div v-if="billDateState === 'future'">
          <strong>
            Lasku lähetetään automaattisesti laskun päiväyksen mukaisesti (siirtyy lähetystä
            odottaviin)
          </strong>

          <h4 class="warning--text mt-1 mb-1">
            Jos haluat lähettää laskun heti, siirrä laskun päiväys tälle päivälle.
          </h4>
        </div>

        <!-- TODAY -->
        <div v-if="billDateState === 'today'" class="mb-1">
          <strong> Lasku lähetetään heti </strong>
        </div>

        <p :class="billDateState === 'past' ? 'error--text' : ''">
          Laskun päivä: {{ formatDate(billDate) }}
        </p>
        <p>Kirjauspäivä: {{ formatDate(accountDate) }}</p>
        <p>Eräpäivä: {{ formatDate(invoice.dueDate) }}</p>
        <p>Maksuehto: {{ paymentTerm }} päivää</p>
      </v-card-text>

      <v-card-actions>
        <v-btn class="mt-1" color="error" :disabled="loading" outlined @click="dialog = false"
          >Poistu</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :disabled="billDateState === 'past'"
          class="mt-1"
          color="info"
          @click="sendInvoiceToService"
          >{{ billDateState === "future" ? "Siirrä odottaviin" : "Lähetä lasku" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    invoice: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    billDateState() {
      const today = moment().format("YYYY-MM-DD");
      const billDate = moment(this.invoice.billDate).format("YYYY-MM-DD");

      if (moment(billDate).isSame(today)) return "today";
      else if (moment(billDate).isAfter(today)) return "future";
      else return "past";
    },

    billDate() {
      return moment(this.invoice.billDate).format("YYYY-MM-DD");
    },

    accountDate() {
      if (this.billDateState === "future")
        return moment(this.invoice.accountDate).format("YYYY-MM-DD");
      else {
        const today = moment().format("YYYY-MM-DD");
        if (moment(today).isSame(moment(this.invoice.billDate).format("YYYY-MM-DD"))) {
          return moment(this.invoice.accountDate).format("YYYY-MM-DD");
        } else {
          return moment().format("YYYY-MM-DD");
        }
      }
    },

    paymentTerm() {
      if (this.billDateState === "future") return this.invoice.firstPaymentTerm;
      else {
        const dueDate = moment(this.invoice.dueDate).format("YYYY-MM-DD");
        return moment(dueDate).diff(this.billDate, "days");
      }
    },
  },

  methods: {
    ...mapActions("invoice", ["sendInvoice"]),

    async sendInvoiceToService() {
      try {
        this.loading = true;

        await this.sendInvoice({ invoiceId: this.invoice._id });
        this.loading = false;
        this.dialog = false;
      } catch (err) {
        this.$emit("getdata");
        this.loading = false;
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style></style>
